<template>
  <v-container v-if="promotion">
    <v-card :width="width" :height="height" outlined raised ripple>
      <v-card-text class="pb-0 m-0">
        <v-row no-gutters>
          <v-col cols="4">
            <span class="title-4 font-weight-bold">
              <span v-if="promotion.amount_type === '%'">
                {{ promotion.amount }}%
              </span>
              <span v-else>
                {{ promotion.amount | currency }}
              </span>
              OFF
            </span>
          </v-col>
          <v-col cols="8">
            <span
              class="subtitle-4 font-italic"
              v-if="promotion.list && promotion.list.length > 0"
              >CODE: {{ promotion.list[0] }}</span
            >
            <span
              class="subtitle-4 font-italic"
              v-if="
                promotion.promo_category === 'global' ||
                  promotion.promo_category === 'menu'
              "
              >{{ promotion.title }}</span
            >
          </v-col>
        </v-row>

        <!-- <v-layout row wrap>
      <v-flex xs12>{{ promotion.detail }}</v-flex>
        </v-layout>-->

        <!-- business info -->
        <v-row no-gutters>
          <v-col cols="6">
            <span class="overline">{{ profile.name }}</span>
          </v-col>
          <v-col cols="6" v-if="imgRatio > 1">
            <span class="overline">{{ profile.email }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="imgRatio > 1">
          <v-col cols="12">
            <span class="caption">{{ producerUrl }}</span>
          </v-col>
        </v-row>
        <v-row align="end" justify="start">
          <v-col cols="12">{{ detail }}</v-col>
        </v-row>
      </v-card-text>

      <v-footer absolute height="auto" color="transparent">
        <v-row justify="center" align="end">
          <v-col cols="8">
            <qr-code :size="imgHeight" :text="productUrl"></qr-code>
          </v-col>
          <v-col cols="4" justify="end" class="text-right pa-1">
            <img
              v-if="card_logo"
              :src="card_logo"
              class="text-right"
              :width="imgHeight"
              :height="imgHeight"
            />
          </v-col>
        </v-row>
      </v-footer>
    </v-card>

    <v-row class="mt-3">
      <v-col cols="6">
        <v-btn small class="no-print" color="info" @click.prevent="print">
          Print
          <v-icon right>print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    promotion: Object,
    coupon_id: String,
    promotion_id: String,
    producer_id: String,
    card_size: String,
    card_logo: String,
    detail: String,
  },

  computed: {
    ...mapGetters({
      profile: "profile",
    }),

    printUrl() {
      return `https://render.takein.com/dish/promo/${this.producer_id}?c=${
        this.coupon_id
      }&p=${this.promotion_id}`;
    },
    productUrl() {
      return `https://${process.env.VUE_APP_DOMAIN}/chef/${
        this.producer_id
      }?c=${this.coupon_id}`;
    },

    producerUrl() {
      let url = `https://${process.env.VUE_APP_DOMAIN}/chef/${this.profile.id}`;
      if (this.profile.handle) {
        url = `https://${process.env.VUE_APP_DOMAIN}/producer/${
          this.profile.handle
        }`;
      }
      return url;
    },
    width() {
      return this.card_size.split(" x ")[0] + "in";
    },
    height() {
      return this.card_size.split(" x ")[1] + "in";
    },
    imgRatio() {
      return this.card_size.split(" x ")[1] / 2;
    },
    imgHeight() {
      return 60 * this.imgRatio;
    },
  },
  methods: {
    print() {
      this.$nextTick(() => {
        window.print();
      });
    },
  },
};
</script>
<style scoped>
@media_3 print {
  @page {
    size: 6in 4in;
    margin: 1in;
  }
}
</style>
